<template>
  <div class="fill-height black px-4 py-12">
    <v-container>
      <v-progress-circular
        v-if="loading"
        :size="200"
        color="green darken-2"
        indeterminate
      ></v-progress-circular>
      <v-card
        v-if="successText"
        class="pr-14 pl-8"
        style="border-right: 2px solid #4F4F4F; border-radius: 0"
      >
        <base-subtitle
          :title="successText"
          class="text-uppercase mt-1"
        />
      </v-card>
      <v-card
        v-if="errorText"
        class="pr-14 pl-8"
        style="border-right: 2px solid #4F4F4F; border-radius: 0"
      >
        <base-subtitle
          :title="errorText"
          class="text-uppercase mt-1"
        />
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import axios from 'axios'
  import { api } from '../../config'

  export default {
    name: 'EmailVerify',

    metaInfo: { title: 'Email Verify' },

    props: {
      type: {
        type: String,
        default: 'new',
      },
      code: {
        type: String,
        default: 'code',
      },
    },

    data: () => ({
      loading: true,
      successText: null,
      errorText: null,
    }),

    mounted () {
      this.loading = true
      setTimeout(this.sendCode, 3000)
    },

    methods: {
      sendCode () {
        var self = this
        axios.post(api.path('verify.type', { type: this.type }), { code: this.code })
          .then(res => {
            console.log('status:' + res.status)
            console.log('successText0:' + self.successText)
            self.successText = 'Your email successfully verified.'
            self.errorText = null
            console.log('successText1:' + self.successText)
            setTimeout(() => { this.$router.push({ name: 'Home' }) }, 10000)
          })
          .catch(() => {
            self.successText = null
            self.errorText = 'Some error.'
          })
          .then(() => {
            this.loading = false
          })
      },
    },
  }
</script>

<style scoped>

</style>
